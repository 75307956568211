import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Button, DropdownButton, Dropdown } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useContextMenu, TriggerEvent } from 'react-contexify';
import {
  Column,
  useTable,
  useSortBy,
  useFlexLayout,
  useFilters,
  usePagination,
  TableInstance,
  Row,
  useResizeColumns,
  useGlobalFilter,
} from 'react-table';

import { TABLE_COLUMNS, FILTERS_DEFAULT } from './Table/Table.config.react';
import SelectFilter from './Table/SelectFilter/SelectFilter.react';
import PoliciesTable from './Table/Table.react';
import ContextMenu from './ContextMenu/ContextMenu.react';
import { CONTEXT_MENU_ID } from './Table/Table.config.react';
import { TOP_ROW, CONTENT } from './Table/Table.config.react';

import { Card, Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import SearchFilterBar from '~reactComponents/SearchFilterBar/SearchFilterBar.react';
import DescriptionPanel from '~reactComponents/DescriptionPanel/DescriptionPanel.react';
import DeleteModal from '~reactComponents/DeleteModal/DeleteModal.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ShowingResults from '~reactComponents/ShowingResults/ShowingResults.react';

import { FormattedPolicies, Policy } from './AllPolicies.types.react';
import { IRoute, IRouter, IStore, OptionType } from '~globalTypes';

import logger from '~logger';
import { sendRequest } from '~utils/request';
import { getSeverityLabel, sortAscending } from '~utils/helpers';
import { handlePageChange, hasKey, displayToast, getCurrentTimezone } from '~reactHelpers';
import { HighThreshold } from '~utils/constants';
import { strings } from '~utils/strings';

import { ALL_POLICIES, ORG_DASHBOARD, POLICY_WIZARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import './AllPolicies.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

type AllPoliciesProps = {
  router: () => IRouter;
  route: IRoute;
  store: () => IStore;
};

type Filter = { id: string; value: Record<string, string> };

type DefaultFilters = Record<string, string[]>;

const AllPolicies = ({ router, route, store }: AllPoliciesProps) => {
  const org = route?.params?.org;
  const breadcrumbRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ALL_POLICIES,
      label: strings.navigation.Policy,
      href: `/orgs/${org}/policy`,
    },
    {
      id: 'last',
      label: strings.policies.allPolicies,
      href: `/orgs/${org}/policy`,
      isActive: true,
    },
  ];
  const PAGE_SIZE_OPTIONS = [10, 20, 50];
  const baseUrl = `/v0/organizations/${org}/opa/customChecks`;

  const [status, setStatus] = useState<string>('idle');
  const [policies, setPolicies] = useState<FormattedPolicies[]>([]);
  const [syncedDate, setSyncedDate] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(50);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedPolicy, setSelectedPolicy] = useState<FormattedPolicies | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const newFilters = useRef<DefaultFilters>(FILTERS_DEFAULT);
  const currentFilters = useRef<Filter[]>([]);

  const { show: showContextMenu } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  const timeZone = getCurrentTimezone();

  useEffect(() => logger.logEvent('policies: visited main policies page'), []);

  const ColumnFiltering = (tableInstance: TableInstance): JSX.Element => (
    <SelectFilter tableInstance={tableInstance} filters={newFilters.current} />
  );
  const tableData = useMemo(() => policies, [policies]);
  const columns = useMemo<Column[]>(() => TABLE_COLUMNS(ColumnFiltering), []);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows: Row<any>[], id: string[], filterValue: OptionType[]) => {
        if (!filterValue.length) {
          return rows;
        }
        const currentID = id[0];
        return rows.filter((row: Row) => {
          const rowValue = row.values[currentID];
          if (rowValue !== undefined) {
            const final = filterValue.map((filter: OptionType) => {
              return String(rowValue).toLowerCase().includes(String(filter?.value).toLowerCase());
            });
            return final.includes(true);
          } else {
            return true;
          }
        });
      },
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      filterTypes,
      autoResetSortBy: false,
      initialState: {
        filters: currentFilters.current,
        pageIndex: 0,
        pageSize: pageSize,
        sortBy: [
          {
            id: 'type',
            desc: false,
          },
          {
            id: 'title',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
  );

  const checkWarning = (policy: Policy, severity: number, key: string) => {
    const warning: any = hasKey(policy, key) ? policy[key] : {};

    if (!warning || !Object.keys(warning).length) {
      if (policy.reportType === 'OPA') return strings.policies.Unknown;

      if (severity >= HighThreshold) {
        return strings.policies.Block;
      } else {
        return strings.policies.Warn;
      }
    } else if (hasKey(warning, 'Block')) {
      return warning?.Block ? strings.policies.Block : strings.policies.Warn;
    }
    return strings.general.na;
  };

  const getPolicies = async (endpoint: string) => {
    let response;

    try {
      response = await sendRequest('GET', `/v0/organizations/${org}/${endpoint}`, {}, null);
    } catch (e) {
      setStatus('rejected');
      logger.logError('policies:error_retrieving_policies', e);
    }
    return response;
  };

  useEffect(() => {
    if (tableInstance.state.filters.length) {
      localStorage.setItem('filters', JSON.stringify(tableInstance.state.filters));
    }
  }, [tableInstance.state.filters]);

  const setAllPolicies = async () => {
    const reportPolicies = await getPolicies('policies');
    const opaPolicies = await getPolicies('opa/customChecks');
    const allPolicies: Policy[] = [];
    const dates: string[] = [];

    if (reportPolicies) {
      Object.keys(reportPolicies.Checks).forEach((report) => {
        if (report !== 'opa') {
          const policyKeys = Object.keys(reportPolicies?.Checks[report]);
          policyKeys.forEach((policy) => {
            const targetPolicy = reportPolicies?.Checks[report][policy];
            targetPolicy.reportType = report;
            targetPolicy.eventType = policy;
            allPolicies.push(targetPolicy);
          });
        }
      });
    } else if (!opaPolicies.length && !reportPolicies) {
      setStatus('rejected');
    }

    const formattedCustom = opaPolicies.map((opaPolicy: Policy) => {
      const updatedOpaPolicy = {
        ...opaPolicy,
        reportType: 'opa',
        eventType: opaPolicy?.Name,
        Admission: {},
        Ci: {},
        SupportedContext: ['Agent'],
        ...reportPolicies.Checks.opa[opaPolicy?.Name],
      };

      return updatedOpaPolicy;
    });

    const formatted: FormattedPolicies[] = [...allPolicies, ...formattedCustom].map((policy) => {
      const severity = policy.SeverityValue || policy.Severity;

      if (policy.Customized) {
        dates.push(
          timeZone
            ? dayjs(policy.UpdatedAt).utc().local().tz(timeZone).format()
            : dayjs(policy.UpdatedAt).utc().local().format(),
        );
      }

      const agentSeverity =
        !policy.IsLibrary && policy.SupportedContext && policy.SupportedContext.includes('Agent')
          ? strings.policies.Warn
          : strings.general.na;

      const admissionSeverity =
        !policy.IsLibrary && policy.SupportedContext && policy.SupportedContext.includes('Admission')
          ? checkWarning(policy, severity, 'Admission')
          : strings.general.na;

      const ciSeverity =
        !policy.IsLibrary && policy.SupportedContext && policy.SupportedContext.includes('CI')
          ? checkWarning(policy, severity, 'Ci')
          : strings.general.na;

      return {
        title: policy.reportType !== 'opa' ? policy.Title : policy.Name || strings.policies.noName,
        type: policy.reportType || strings.policies.Unknown,
        enabled: !policy.Disabled || false,
        eventType: policy.eventType,
        category: policy.Category || strings.policies.Unknown,
        severity: getSeverityLabel(severity) || strings.severities.none,
        severityValue: policy.SeverityValue,
        customized: policy.Customized ? strings.policies.Customized : strings.policies.Default,
        agent: agentSeverity,
        admission: admissionSeverity,
        ci: ciSeverity,
        description: policy.Description || '',
        remediation: policy.Remediation || '',
        created: policy.CreatedAt !== strings.noTranslate.baseDate ? policy.CreatedAt : '',
        updated: policy.UpdatedAt !== strings.noTranslate.baseDate ? policy.UpdatedAt : '',
        isLibrary: policy.IsLibrary || false,
      };
    });

    setTotalSize(formatted.length);

    if (dates.length) {
      const filteredDates = dates.filter((date) => date).sort((a, b) => sortAscending(a, b));
      const mostRecentDate = filteredDates[filteredDates.length - 1];
      const day = timeZone
        ? dayjs(mostRecentDate).tz(timeZone).format('MM/DD/YYYY')
        : dayjs(mostRecentDate).format('MM/DD/YYYY');
      const time = timeZone
        ? dayjs(mostRecentDate).tz(timeZone).format('h:mm A')
        : dayjs(mostRecentDate).format('h:mm A');

      setSyncedDate(`${strings.policies.syncDate.replace('$day', day)} ${time}`);
    } else {
      setSyncedDate(strings.policies.cliToCustomize);
    }
    setPolicies(formatted);
    setStatus(strings.noTranslate.resolved);
  };

  useEffect(() => {
    if (localStorage.getItem('filters')) {
      const filters = JSON.parse(localStorage.getItem('filters') || '');
      if (filters) currentFilters.current = filters;
    }

    return () => {
      localStorage.removeItem('filters');
    };
  }, []);

  useEffect(() => {
    setStatus('pending');
    setAllPolicies();
  }, [pageSize]);

  const changePageSize = (sizeOption: number) => {
    setPageSize(sizeOption);
    setPageIndex(0);
    logger.logEvent('policies:change-results-per-page', sizeOption);
  };

  const searchFunction = (event: unknown) => {
    const typedEvent = event as Event;
    const value = typedEvent.target as HTMLInputElement;
    setSearchValue(value.value);
  };

  const formattedPolicy = useMemo(() => {
    if (selectedPolicy) {
      return {
        metadata: TOP_ROW(selectedPolicy),
        content: CONTENT(selectedPolicy),
        created: selectedPolicy.created || null,
        updated: selectedPolicy.updated || null,
        title: selectedPolicy.title || null,
        severity: selectedPolicy.severity || null,
        category: selectedPolicy.category || null,
      };
    }
    return null;
  }, [selectedPolicy]);

  const openContextMenu = (e: TriggerEvent) => {
    return showContextMenu({ event: e, props: { policy: selectedPolicy } });
  };

  const handleOnDelete = async () => {
    try {
      await sendRequest('DELETE', `${baseUrl}/${selectedPolicy?.title}`, {}, null);
      displayToast(strings.policies.policyRemoved.replace('$title', selectedPolicy?.title), false, store);
      setShowDeleteModal(false);
      setSelectedPolicy(null);
      setAllPolicies();
    } catch (e) {
      if (selectedPolicy?.title === 'No Name') {
        displayToast(strings.policies.errorNoName, true, store);
      } else {
        displayToast(strings.policies.errorRemovingPolicy.replace('$title', selectedPolicy?.title), true, store);
      }
      logger.logError('error_fetching_policies', e);
    }
    logger.logEvent('policies: OPA policy deleted in pop up');
  };

  return (
    <LayoutReact className="policies">
      <Breadcrumbs
        data={breadcrumbRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="policies__top-bar">
        <SearchFilterBar searchFunction={searchFunction} className="policies__search-bar" />
        <Button
          variant="primary"
          className="policies__create-button"
          onClick={() => {
            logger.logEvent('all-policies: create OPA button clicked');
            handlePageChange(router, POLICY_WIZARD);
          }}
          data-cy="create-opa-policy-button"
        >
          {strings.policies.createOPA}
        </Button>
      </div>
      <Card data-cy="policies-card">
        <Card.Body padded>
          <Card.Header>
            <ShowingResults
              size={pageSize}
              index={pageIndex}
              numOfRows={totalSize}
              className="policies__total-results"
            />
            <div className="policies__page-size__dropdown-section">
              <span>{strings.resultsPerPage}</span>
              <DropdownButton title={pageSize} size="sm" className="policies__page-size__dropdown__selection">
                {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
                  <Dropdown.Item
                    key={idx}
                    onClick={() => {
                      changePageSize(pageSizeOption);
                    }}
                  >
                    {pageSizeOption}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
          </Card.Header>
          <div className="policies__synced-div">
            <div className="policies__synced-div-section">
              <div className={`policies__synced-${syncedDate.includes('CLI') ? 'red' : 'green'}-circle`}></div>
              <a
                className="policies__synced-bold"
                href="https://insights.docs.fairwinds.com/configure/cli/settings/#pushing-policies-configuration-to-insights"
                target="_blank"
              >
                {syncedDate.includes(strings.policies.CLI)
                  ? syncedDate
                  : strings.policies.lastSynced.replace('$syncedDate', syncedDate)}
              </a>
            </div>
          </div>
          {status !== strings.noTranslate.resolved && status !== strings.noTranslate.rejected && <LoadingSpinner />}
          {status === strings.noTranslate.resolved && policies.length > 0 && (
            <div className="policies__table-container">
              <PoliciesTable
                tableInstance={tableInstance}
                currentFilters={currentFilters}
                pageSizeSelected={pageSize}
                setSelectedPageIndex={setPageIndex}
                setTotalSize={setTotalSize}
                searchValue={searchValue}
                policy={selectedPolicy}
                setPolicy={setSelectedPolicy}
              />
              <DescriptionPanel
                selectedItem={formattedPolicy}
                setSelectedItem={setSelectedPolicy}
                page="policies"
                openContextMenu={selectedPolicy?.type === 'opa' ? openContextMenu : null}
              />
            </div>
          )}
          {(status === strings.noTranslate.resolved || status === strings.noTranslate.rejected) && !policies.length && (
            <h1 className="policies__no-policies">{strings.policies.noPolicies}</h1>
          )}
          <ContextMenu router={router} setModal={setShowDeleteModal} />
        </Card.Body>
      </Card>
      <DeleteModal
        page={strings.policies.opaPolicy}
        text={strings.policies.wantToDelete.replace('$title', selectedPolicy ? selectedPolicy.title : '')}
        name={selectedPolicy ? selectedPolicy.title : ''}
        deleteFunction={handleOnDelete}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      />
    </LayoutReact>
  );
};

export default AllPolicies;
