import React, { useEffect, SetStateAction } from 'react';
import { TableInstance } from 'react-table';
import PaginationComponent from '~reactComponents/pagination/pagination.react';
import logger from '~logger';
import { FormattedPolicies } from '../AllPolicies.types.react';
import './Table.react.scss';

type Filter = { id: string; value: Record<string, string> };

type PoliciesTableProps = {
  tableInstance: TableInstance;
  currentFilters: { current: Filter[] };
  pageSizeSelected: number;
  setSelectedPageIndex: React.Dispatch<SetStateAction<number>>;
  setTotalSize: React.Dispatch<SetStateAction<number>>;
  searchValue: string;
  policy: FormattedPolicies | null;
  setPolicy: React.Dispatch<SetStateAction<FormattedPolicies | null>>;
};

const PoliciesTable = ({
  tableInstance,
  currentFilters,
  pageSizeSelected,
  setSelectedPageIndex,
  setTotalSize,
  searchValue,
  policy,
  setPolicy,
}: PoliciesTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    filteredRows,
    setGlobalFilter,
    state: { pageIndex },
  } = tableInstance;

  useEffect(() => setGlobalFilter(searchValue || undefined), [searchValue]);

  useEffect(() => {
    if (tableInstance.state.filters.length) {
      currentFilters.current = tableInstance.state.filters;
    }
  }, [tableInstance.state.filters]);

  useEffect(() => setPageSize(pageSizeSelected), [pageSizeSelected]);

  const handlePaginationChange = (page: number): void => {
    setSelectedPageIndex(page);
    gotoPage(page);
    logger.logEvent('all-policies-table:changed policies page number', page);
  };

  useEffect(() => {
    setTotalSize(filteredRows.length);
  }, [filteredRows]);

  return (
    <div className="policies-table-component">
      <table {...getTableProps()} className="policies-table-component__table" data-cy="policies-table">
        <thead className="policies-table-header">
          {headerGroups.map((headerGroup) => (
            <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span className="policies-table-header__icon">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fas fa-arrow-down" />
                      ) : (
                        <i className="fas fa-arrow-up" />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                  {column.id === 'title' ? (
                    <div {...column.getResizerProps()} className="draggable-column" />
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="policies-table-body">
          {page &&
            page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={
                    policy?.title === (row.original as FormattedPolicies).title &&
                    policy?.type === (row.original as FormattedPolicies).type
                      ? 'selected-policy'
                      : ''
                  }
                  onClick={() => {
                    logger.logEvent('all-policies-table: additional policy detail opened');
                    logger.logEvent(
                      `policies-table:selected ${row?.original?.category} category item`
                    );
                    setPolicy(row.original as FormattedPolicies);
                  }}
                >
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
      <PaginationComponent
        currentPage={pageIndex}
        pageChange={handlePaginationChange}
        totalCount={Math.ceil(filteredRows.length / pageSizeSelected)}
      />
    </div>
  );
};

export default PoliciesTable;
