import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button, Card } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import {
  ORG_DASHBOARD,
  COMPLIANCE,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import SearchFilterBar from '~reactComponents/SearchFilterBar/SearchFilterBar.react';
import CheckTypesCard from '~reactComponents/cards/CheckTypes/CheckTypes.react';
import OverallProgressCard from '~reactComponents/cards/ReportProgress/ReportProgress.react';
import ComplianceCard from '~reactComponents/cards/ComplianceCard/ComplianceCard.react';
import DownloadIcon from '~reactIcons/Download.react';
import SelfAssessmentModal from './AssessmentModal.react';
import AutomatedCheckModal from './AutomatedCheckModal.react';

import { handlePageChange } from '~reactHelpers';
import { standardizedReports, formatStatus } from '../Compliance.helpers.react';
import { sendRequest } from '~utils/request';
import { sortDescending } from '~utils/helpers';
import { ReportFilter } from './Report.helpers.react';
import logger from '~logger';

import { IStore, IRoute, IRouter, OptionType } from '~globalTypes';
import { ReportsSummary, StandardsSummary, Check, Labels } from '../Compliance.types.react';

import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import './Report.react.scss';

type ReportProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const Report = ({ route, router, store }: ReportProps): JSX.Element => {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const isOwner = store().getters.isOrgOwner;

  const [report, setReport] = useState<ReportsSummary | null>(null);
  const [totalTypes, setTotalTypes] = useState<StandardsSummary[]>([]);
  const [statusDropdownOptions, setStatusDropdownOptions] = useState<OptionType[]>([]);
  const [typeDropdownOptions, setTypeDropdownOptions] = useState<OptionType[]>([]);
  const [controlIdDropdownOptions, setControlIdDropdownOptions] = useState<OptionType[]>([]);
  const [statusDropdown, setStatusDropdown] = useState<OptionType>({
    label: strings.noTranslate.allStatuses,
    value: strings.compliance.allStatuses,
  });
  const [typeDropdown, setTypeDropdown] = useState<OptionType>({
    label: strings.noTranslate.allTypes,
    value: strings.compliance.allTypes,
  });
  const [controlIdDropdown, setControlIdDropdown] = useState<OptionType>({
    label: strings.noTranslate.allControlIds,
    value: strings.compliance.allControlIds,
  });
  const [checksToDisplay, setChecksToDisplay] = useState<Check[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCheck, setSelectedCheck] = useState<Check | null>(null);
  const [isAutomatedModalShown, setIsAutomatedModalShown] = useState<boolean>(false);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: COMPLIANCE,
      label: 'Compliance Reports',
      href: `/orgs/${org}/compliance`,
    },
    {
      id: 'last',
      label: report?.name || '',
      href: ``,
      isActive: true,
    },
  ];

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    const formattedStatus = reformatStatus(statusDropdown.value);
    const formatttedType = reformatType(typeDropdown.value);
    const filter = new ReportFilter();
    const filteredChecks = filter.calc({
      checks: report?.checks ? [...report.checks] : [],
      status: formattedStatus,
      type: formatttedType,
      controlId: controlIdDropdown.value,
      search: searchValue,
    });
    setChecksToDisplay(filteredChecks || []);
  }, [searchValue, statusDropdown, typeDropdown, controlIdDropdown]);

  const updateSelectedCheck = (checks: Check[]) => {
    if (!checks?.length || !selectedCheck) {
      return;
    }
    const updatedCheck = checks.find((check) => check.id === selectedCheck.id);
    if (updatedCheck) {
      setSelectedCheck(updatedCheck);
    }
  };

  const getReport = async () => {
    const reportID = route?.query?.reportID;
    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/compliance/reports/${reportID}?newStatuses=true`,
        {},
        null,
      );
      const sortedChecks = movePassing(
        response.checks.sort((a: Check, b: Check) => sortDescending(a.status, b.status)),
      );

      setTotalTypes(response.standardsSummaries);
      setChecksToDisplay(sortedChecks);
      setReport(response);
      setStatuses(response.checks);
      setDropdownListOptions(response.standards);
      setControlIdOptions(response.checks);
      updateSelectedCheck(sortedChecks);
    } catch (e) {
      logger.logError('error_retrieving_report', e);
      toast.error(<b>{strings.compliance.noReport}</b>);
    }
  };

  const movePassing = (checks: Check[]) => {
    if (!checks?.length) return [];
    const passingChecks = checks.filter((check: Check) => check.status === Labels.passing);
    const removedPassingChecks = checks.filter((check: Check) => check.status !== Labels.passing);
    return removedPassingChecks.concat(passingChecks);
  };

  const setStatuses = (checks: Check[]) => {
    const statuses: OptionType[] = [
      { label: strings.compliance.allStatuses, value: 'All Statuses' },
    ];
    checks.forEach((check) => {
      const formattedStatus = formatStatus(check.status);
      if (!statuses.find((status) => status.value === formattedStatus)) {
        statuses.push({ label: formattedStatus, value: formattedStatus });
      }
    });
    setStatusDropdownOptions(statuses);
  };

  const DisplayChecks = () => {
    let allChecks;

    if (!checksToDisplay.length) {
      return (
        <div>
          <h3>{strings.compliance.noChecks}</h3>
        </div>
      );
    } else if (checksToDisplay.length > 0) {
      allChecks = checksToDisplay.map((check) => {
        return (
          <ComplianceCard
            check={check}
            key={check.id}
            reportStandards={report ? report?.standards : ['']}
            setIsAutomatedModalShown={setIsAutomatedModalShown}
            setModal={setShowModal}
            setSelectedCheck={setSelectedCheck}
          />
        );
      });
    } else {
      if (report) {
        allChecks = report?.checks?.map((check) => {
          return (
            <ComplianceCard
              check={check}
              key={check.id}
              reportStandards={report?.standards}
              setIsAutomatedModalShown={setIsAutomatedModalShown}
              setModal={setShowModal}
              setSelectedCheck={setSelectedCheck}
            />
          );
        });
      }
    }

    return <>{allChecks}</>;
  };

  const setDropdownListOptions = (standards: string[]) => {
    const updatedStandards = [
      { label: strings.compliance.allTypes, value: strings.noTranslate.allTypes },
    ];

    standards.forEach((standard) => {
      const convertedType = standardizedReports(standard);
      updatedStandards.push({ label: convertedType, value: convertedType });
    });

    setTypeDropdownOptions(updatedStandards);
  };

  const setControlIdOptions = (checks: Check[]) => {
    if (!checks?.length) {
      return;
    }
    let controlIds: OptionType[] = [];
    for (const check of checks) {
      if (check?.standards?.length) {
        for (const standard of check.standards) {
          const name = standardizedReports(standard.name);
          const codes = standard.codes;
          if (codes?.length) {
            for (const code of codes) {
              if (!controlIds.find((id) => id.value === code)) {
                controlIds.push({ value: code, label: `${name} - ${code}` });
              }
            }
          }
        }
      }
    }
    controlIds.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    controlIds = [
      { value: strings.noTranslate.allControlIds, label: strings.compliance.allControlIds },
      ...controlIds,
    ];
    setControlIdDropdownOptions(controlIds);
  };

  const reformatStatus = (selection: string) => {
    switch (selection) {
      case strings.checkStatus.notImplemented:
      case strings.checkStatus.notStarted:
        return Labels.notStarted;
      case strings.checkStatus.needsEvidence:
        return Labels.needsEvidence;
      case strings.checkStatus.implemented:
      case strings.checkStatus.passing:
        return Labels.passing;
      case strings.checkStatus.notInScope:
        return Labels.notInScope;
      case strings.checkStatus.failing:
        return Labels.failing;
      default:
        return selection;
    }
  };

  const reformatType = (type: string) => {
    switch (type) {
      case strings.reportTypes.soc2:
        return Labels.soc2;
      case strings.reportTypes.iso27001:
        return Labels.iso27001;
      case strings.reportTypes.allTypes:
        return strings.reportTypes.allTypes;
      case strings.reportTypes.nsa:
        return strings.noTranslate.nsa;
      case strings.checkType:
        return strings.checkType;
    }
    return typeDropdown?.value.toLowerCase();
  };

  const filteringStatus = (selection: OptionType) => {
    setStatusDropdown(selection);
  };

  const filteringType = (selection: OptionType) => {
    setTypeDropdown(selection);
  };

  const filteringControlId = (selection: OptionType) => {
    setControlIdDropdown(selection);
  };

  const searchForItems = (event: unknown, searchTerm?: string): void => {
    const typedEvent = event as Event | null;
    const inputSearchValue = typedEvent?.target as HTMLInputElement;
    setSearchValue(searchTerm ? searchTerm : inputSearchValue.value);
  };

  const addCheckEvidence = async (
    evidence: { evidence: string; status: string; mode: string },
    id: number,
  ) => {
    try {
      await sendRequest(
        'PUT',
        `${baseURL}/compliance/reports/${report?.id}/checks/${id}/self-assessment?newStatuses=true`,
        { data: evidence },
        null,
      );
      toast.success(<b>{strings.compliance.updatedReport}</b>);
    } catch (e) {
      logger.logError('error_adding_compliance_check_evidence', e);
      toast.error(<b>{strings.compliance.unsuccessfulUpdate}</b>);
    }
    getReport();
  };

  const goToDownloadReport = () => {
    const route = router().resolve({
      name: 'download-report',
      query: { reportID: report?.id },
    });
    return window.open(route.href, '_blank');
  };

  const onRunAutomatedCheckTriggered = () => {
    getReport();
  };

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="cards-div">
        <div className="cards-div_left">
          <CheckTypesCard totalTypes={totalTypes} />
        </div>
        <div className="cards-div_right">
          <OverallProgressCard
            summary={report?.summary || { implemented: 0, notImplemented: 0, needsEvidence: 0 }}
          />
        </div>
      </div>
      <Card className="checks-div">
        <Card.Title className="checks-title">
          <span>{strings.compliance.title}</span>
        </Card.Title>
        <Card.Subtitle>
          <span>{strings.compliance.subtitle}</span>
        </Card.Subtitle>
        <Button className="download-div" onClick={goToDownloadReport}>
          <DownloadIcon marginRight="0.5rem" fill={COLORS.CORE.PRIMARY} />
          {strings.compliance.download}
        </Button>
        <SearchFilterBar searchFunction={searchForItems}>
          <Select
            className="compliance-report-select-dropdown compliance-status-select"
            classNamePrefix="compliance-status-select"
            isMulti={false}
            isSearchable={true}
            onChange={filteringStatus}
            options={statusDropdownOptions}
            placeholder="Check Status"
            value={statusDropdown}
          />
          <Select
            className="compliance-report-select-dropdown compliance-type-select"
            classNamePrefix="compliance-type-select"
            isMulti={false}
            isSearchable={true}
            onChange={filteringType}
            options={typeDropdownOptions}
            placeholder="Check Type"
            value={typeDropdown}
          />
          <Select
            className="compliance-report-select-dropdown compliance-control-id-select"
            classNamePrefix="compliance-control-id-select"
            isMulti={false}
            isSearchable={true}
            onChange={filteringControlId}
            options={controlIdDropdownOptions}
            placeholder="Control ID"
            value={controlIdDropdown}
          />
        </SearchFilterBar>
        {report?.checks && report?.checks?.length > 0 && <DisplayChecks />}
        {report?.checks?.length === 0 && (
          <h3 className="checks-error">{strings.compliance.noChecksForStandard}</h3>
        )}
      </Card>
      {showModal && (
        <SelfAssessmentModal
          show={showModal}
          setModal={setShowModal}
          isOwner={isOwner}
          check={selectedCheck}
          addEvidence={addCheckEvidence}
        />
      )}
      {isAutomatedModalShown && (
        <AutomatedCheckModal
          addEvidence={addCheckEvidence}
          baseURL={baseURL}
          check={selectedCheck}
          clusters={report?.clusters}
          isOwner={isOwner}
          onRunAutomatedCheckTriggered={onRunAutomatedCheckTriggered}
          organizationName={org}
          reportID={route?.query?.reportID}
          setModal={setIsAutomatedModalShown}
          show={isAutomatedModalShown}
        />
      )}
      <Toaster />
    </LayoutReact>
  );
};

export default Report;
