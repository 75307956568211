import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import clsx from 'clsx';

import { NotificationsPreferences } from '~views/organization/settings/notifications/Notifications.types.react';
import { OptionType } from '~globalTypes';

import { strings } from '~utils/strings';
import { CardTitleDefault, BasicText } from '~utils/texts.react';

import './Notification.react.scss';

type NotificationProps = {
  channel: NotificationsPreferences;
  preferences: NotificationsPreferences[];
  cluster: OptionType;
  updateNotifications: (
    event: React.MouseEvent<HTMLElement>,
    id: NotificationsPreferences,
    selected: string[],
  ) => void;
};

const Notification = ({
  channel,
  preferences,
  cluster,
  updateNotifications,
}: NotificationProps) => {
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    selectCheckboxes();
  }, []);

  const selectCheckboxes = () => {
    setSelected([]);
    let preferenceIndex = -1;

    preferences.forEach((preference, index) => {
      if (preference.Cluster === channel.Cluster) {
        if (preference.ChannelID === channel.ChannelID) {
          preferenceIndex = index;
        }
      }
    });

    if (preferenceIndex > -1 && preferences[preferenceIndex].Digest) {
      setSelected((prevState) => [...prevState, 'daily']);
    }
    if (preferenceIndex > -1 && preferences[preferenceIndex].Realtime) {
      setSelected((prevState) => [...prevState, 'real']);
    }
  };

  const handleChecking = (selection: string) => {
    if (selected.includes(selection)) {
      setSelected(selected.filter((selections) => selections !== selection));
    } else {
      setSelected((prevState) => [...prevState, selection]);
    }
  };

  return (
    <div className="notification-component" data-cy="notification-component">
      <div className="notification-component__slack-card">
        <h2 className={clsx('notification-component-title-slack', CardTitleDefault())}>
          {cluster.value === 'All Clusters' || !cluster.value
            ? `${channel.Cluster ? channel.Cluster : strings.navigation.allClusters} > ${
                channel.name
              }`
            : channel.name}
        </h2>
        <Form>
          <Form.Group data-cy="slack-checkboxes">
            <Form.Label>{strings.notificationPreferences}</Form.Label>
            <label
              className={clsx(
                'notification-checkbox-label',
                BasicText({
                  size: strings.textStyling.md,
                  bottomMargin: strings.textStyling.smBottom,
                }),
              )}
            >
              {strings.realTime}
              <input
                type="checkbox"
                onChange={() => handleChecking('real')}
                checked={selected.includes('real')}
              />
              <span className="checkmark"></span>
            </label>
            <label
              className={clsx(
                'notification-checkbox-label',
                BasicText({
                  size: strings.textStyling.md,
                  bottomMargin: strings.textStyling.smBottom,
                }),
              )}
            >
              {strings.dailyDigest}
              <input
                type="checkbox"
                onChange={() => handleChecking('daily')}
                checked={selected.includes('daily')}
              />
              <span className="checkmark"></span>
            </label>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            className="notification-component__button"
            onClick={(e) => updateNotifications(e, channel, selected)}
          >
            {strings.Update}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Notification;
