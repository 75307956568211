export interface Status {
  slack: string;
  datadog: string;
  github: string;
  jira: string;
  pagerduty: string;
  azure: string;
}

export interface SlackIntegration {
  IsSlackAccessTokenSet: boolean;
  RedirectURL: string;
  SlackClientID: string;
  SlackState: string;
}

export interface HashMap {
  [option: string]: any;
}

export enum IntegrationsChannels {
  Slack = 'slack',
  Jira = 'jira',
  Pagerduty = 'pagerduty',
  Azure = 'azure',
  Datadog = 'datadog',
  Github = 'github',
}

export const integrationsTitles = ['slack', 'datadog', 'github', 'jira', 'pagerduty', 'azure'];
