import React, { useState, useEffect, useMemo } from 'react';
import Notification from '~reactComponents/Settings/Notifications/Notification.react';
import Select, { OptionProps } from 'react-select';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import toast, { Toaster } from 'react-hot-toast';
import { Cluster, OptionType } from '~globalTypes';
import {
  NotificationsProps,
  NotificationsPreferences,
  ChosenChannel,
  SlackChannels,
} from './Notifications.types.react';
import { Breadcrumbs } from '@fairwindsops/ui-components';
import { handlePageChange } from '~utils/global.helpers.react';

import {
  ORG_DASHBOARD,
  TEAM_MANAGEMENT,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';

import './Notifications.react.scss';

const statuses = {
  idle: 'idle',
  pending: 'pending',
  rejected: 'rejected',
  resolved: 'resolved',
  tokenRejected: 'tokenRejected',
};

const Notifications = ({ route, store, router }: NotificationsProps) => {
  const org = route?.params?.org;
  const isOrgOwner = store().getters.isOrgOwner;
  const [selectedCluster, setSelectedCluster] = useState<OptionType>({ value: '', label: '' });
  const [integrated, setIntegrated] = useState<boolean>(true);
  const [status, setStatus] = useState<string>(statuses.idle);
  const [chosenChannel, setChosenChannel] = useState<ChosenChannel>({ ChannelID: '', name: '' });
  const [slackNotificationPreferences, setSlackNotificationPreferences] = useState<
    NotificationsPreferences[]
  >([]);
  const [slackChannels, setSlackChannels] = useState<SlackChannels[]>([]);
  const [slackOptions, setSlackOptions] = useState<OptionType[]>([]);
  const [selectedSlack, setSelectedSlack] = useState<OptionType>({ value: '', label: '' });
  const [dropdownOptions, setDropdownOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    refreshSlack();
  }, [route.path]);

  useEffect(() => {
    refreshChannels(integrated);
  }, [selectedCluster?.value]);

  const baseURL = useMemo(() => {
    let base = '';
    if (!selectedCluster.value || selectedCluster?.value === 'All Clusters') {
      base = `/v0/organizations/${org}`;
    } else {
      base = `/v0/organizations/${org}/clusters/${selectedCluster.value}`;
    }
    return base;
  }, [selectedCluster?.value]);

  const allChannels: NotificationsPreferences[] = useMemo(() => {
    if (!slackNotificationPreferences) {
      return [];
    } else if (selectedCluster.value === 'All Clusters' || !selectedCluster.value) {
      if (!selectedSlack.value) {
        return slackNotificationPreferences.sort(sortChannels);
      }
    }
    return slackNotificationPreferences
      .filter((channel) => channel.Cluster === selectedCluster.value)
      .sort(sortChannels);
  }, [selectedCluster.value, selectedSlack.value, slackNotificationPreferences]);

  const channelsWithChosenChannel = useMemo(() => {
    if (selectedSlack) {
      const match = allChannels.find((channel) => channel.name === selectedSlack.value);
      let chosenMatch;
      if (match) {
        return allChannels;
      } else {
        chosenMatch = {
          ChannelID: chosenChannel.ChannelID,
          Cluster: null,
          DatadogCostMetrics: false,
          Digest: false,
          ID: 0,
          NotificationIntegration: 0,
          Organization: '',
          Realtime: false,
          name: chosenChannel.name,
        };
      }
      return allChannels.concat(chosenMatch);
    }
    return [];
  }, [selectedSlack]);

  const sortChannels = (a: any, b: any) => {
    if (a.name) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  };

  const refreshSlack = async () => {
    try {
      const data = await sendRequest('GET', `${baseURL}/slack/integration`, {}, null);
      setIntegrated(data.IsSlackAccessTokenSet);
      if (!data.IsSlackAccessTokenSet) {
        setStatus(statuses.tokenRejected);
      } else {
        refreshChannels(data.IsSlackAccessTokenSet);
      }
      const clusters = store().getters.clusters;
      const mappedClusters = clusters.map((cluster: Cluster) => ({
        value: cluster.Name,
        label: cluster.Name,
      }));
      setDropdownOptions(
        [
          {
            value: 'All Clusters',
            label: 'All Clusters',
          },
        ].concat(mappedClusters),
      );
    } catch (e) {
      logger.logError('error_get_slack_integration', { message: e.message });
    }
  };

  const refreshChannels = async (accessTokenSet: boolean) => {
    setStatus(statuses.pending);
    setChosenChannel({ ChannelID: '', name: '' });
    if (!accessTokenSet) {
      setSlackNotificationPreferences([]);
      setStatus(statuses.tokenRejected);
    } else {
      setSlackNotificationPreferences([]);
      try {
        const data = await sendRequest('GET', `${baseURL}/slack/settings`, {}, null);
        setSlackChannels(data.SlackChannels);
        const formattedSlackChannels = data.SlackChannels.map((channel: SlackChannels) => ({
          value: channel.name,
          label: channel.name,
        }));
        setSlackOptions(formattedSlackChannels.sort(sortChannels));
        setSlackNotificationPreferences(
          await getSlackChannelNames(data.SlackNotificationPreferences, data.SlackChannels),
        );
        setStatus(statuses.resolved);
      } catch (e) {
        logger.logError('error_get_slack_settings', { message: e.message });
        setStatus(statuses.rejected);
      }
    }
  };

  const getSlackChannelNames = (
    preferences: NotificationsPreferences[],
    channels: SlackChannels[],
  ) => {
    return preferences.map((notification) => {
      const match = channels.find((channel) => channel.id === notification.ChannelID);
      notification.name = match?.name;
      return notification;
    });
  };

  const refreshSlackChannel = (selected: OptionType) => {
    setChosenChannel({ ChannelID: '', name: '' });
    const { id: ChannelID, name } = slackChannels.find(
      (channel) => channel.name === selected.value,
    ) || { id: '', name: '' };
    setChosenChannel({ ChannelID, name });
  };

  const updateNotifications = async (
    event: React.MouseEvent<HTMLElement>,
    channel: NotificationsPreferences,
    selected: string[],
  ) => {
    event.preventDefault();
    const data = {
      ChannelIDs: [channel.ChannelID],
      Digest: selected.includes('daily'),
      Realtime: selected.includes('real'),
    };
    const url =
      channel.Cluster && !baseURL.includes(`/clusters/${channel.Cluster}`)
        ? `${baseURL}/clusters/${channel.Cluster}`
        : baseURL;
    try {
      await sendRequest('POST', `${url}/slack/channel`, { data }, null);
      refreshChannels(integrated);
      toast.success(
        <b>{`Success updating notifications in the ${channel.name} slack channel for ${
          channel.Cluster ? `the ${channel.Cluster} cluster` : 'All Clusters'
        }`}</b>,
      );
      setSelectedSlack({ value: '', label: '' });
    } catch (e) {
      logger.logError('error_updating_slack_notification', e);
      toast.error(
        <b>{`Error adding notifications in the ${channel.name} slack channel for ${
          channel.Cluster ? `the ${channel.Cluster} cluster` : 'All Clusters'
        }`}</b>,
      );
    }
  };

  const handleDropdownChange = (selected: unknown, dropdown: string) => {
    if (dropdown === 'clusters') {
      if (!selected) {
        setSelectedCluster({
          value: 'All Clusters',
          label: 'All Clusters',
        });
      } else {
        setSelectedCluster(selected as OptionType);
      }
      setSelectedSlack({ value: '', label: '' });
    } else {
      if (!selected) {
        setSelectedSlack({ value: '', label: '' });
        return;
      } else {
        setSelectedSlack(selected as OptionType);
        refreshSlackChannel(selected as OptionType);
      }
    }
  };

  const Option = (props: OptionProps<OptionType, true>) => {
    return (
      <div className="select-checkbox" onClick={() => props.selectOption(props.data)}>
        <label>
          <input type="checkbox" id={`${props?.label}-checkbox`} checked={props.isSelected} />
          <span />
        </label>
        <span>{props.label}</span>
      </div>
    );
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${org}/settings`,
    },
    {
      id: 'last',
      label: strings.navigation.notifications,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="notifications-page">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!isOrgOwner && (
        <div className="notifications-page-message">
          <span>You are only allowed to change these settings if you are an owner.</span>
          <span>Please contact your organization's owner to make changes</span>
        </div>
      )}
      {(!integrated || status === 'tokenRejected') && (
        <div className="notifications-page-message" data-cy="no-integration">
          <span>You do not have Slack integrated</span>
          <span>
            Please go to the integrations tab to add Slack to allow Action Item notifications.
          </span>
        </div>
      )}
      {isOrgOwner && (
        <>
          {status === strings.noTranslate.resolved && (
            <div className="notifications-page-dropdowns">
              <div className="notifications-page-dropdown-container">
                <h2
                  className="notifications-page-dropdown-container__title"
                  data-cy="clusters-title"
                >
                  Select the clusters where you would like to receive Slack notifications
                </h2>
                <Select
                  className="clusters-dropdown-input"
                  classNamePrefix="custom_select"
                  components={{ Option }}
                  aria-label="cluster-select-dropdown"
                  isSearchable
                  isClearable
                  options={dropdownOptions}
                  value={selectedCluster}
                  onChange={(selected) => handleDropdownChange(selected, 'clusters')}
                />
              </div>
              <div className="notifications-page-dropdown-container">
                <h2 className="notifications-page-dropdown-container__title">
                  Select the Slack channels where you would like to receive notifications
                </h2>
                <Select
                  components={{ Option }}
                  isSearchable
                  isClearable
                  aria-label="slack-channel-select-dropdown"
                  onChange={(selected) => handleDropdownChange(selected, 'slack')}
                  options={slackOptions}
                  value={selectedSlack}
                />
              </div>
            </div>
          )}
          <div className="notifications-page-display">
            {(status === 'idle' || status === 'pending') && (
              <div className="notifications-page-display__loading">
                <LoadingSpinner />
              </div>
            )}
            {status === strings.noTranslate.resolved && !selectedSlack.value && (
              <>
                {allChannels.map((channel) => (
                  <Notification
                    channel={channel}
                    preferences={slackNotificationPreferences}
                    cluster={selectedCluster}
                    updateNotifications={updateNotifications}
                    key={`${channel.Cluster ? channel.Cluster : 'all'}-${channel.ChannelID}`}
                  />
                ))}
              </>
            )}
            {status === strings.noTranslate.resolved && selectedSlack.value && (
              <>
                {channelsWithChosenChannel.map((channel) => (
                  <Notification
                    channel={channel}
                    preferences={slackNotificationPreferences}
                    cluster={selectedCluster}
                    updateNotifications={updateNotifications}
                    key={`${channel.Cluster ? channel.Cluster : 'all'}-${channel.ChannelID}`}
                  />
                ))}
              </>
            )}
          </div>
        </>
      )}
      <Toaster />
    </div>
  );
};

export default Notifications;
