import React, { useState, useEffect } from 'react';

import ReportIntegrationCard from '~reactComponents/Report-Integration/Report-IntegrationCard.react';
import { Breadcrumbs } from '@fairwindsops/ui-components';
import {
  ORG_DASHBOARD,
  TEAM_MANAGEMENT,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import {
  Status,
  SlackIntegration,
  IntegrationsChannels,
  integrationsTitles,
  HashMap,
} from './Integrations.types.react';
import { IRouter, IStore } from '~globalTypes';
import { UPDATE_ORG } from '~store/action.types';

import { INTEGRATIONS_DETAILS } from '~utils/constants';
import { sendRequest } from '~utils/request';
import { handlePageChange } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';
import logger from '~logger';

import './Integrations.react.scss';

type IntegrationsProps = {
  router: () => IRouter;
  store: () => IStore;
};

const Integrations = ({ router, store }: IntegrationsProps) => {
  const { isOrgOwner, organization, repositories } = store().getters;

  const [status, setStatus] = useState<Status>({
    slack: 'available',
    datadog: 'available',
    github: 'available',
    jira: 'available',
    pagerduty: 'available',
    azure: 'available',
  });
  const [slackClientId, setSlackClientId] = useState<string>();
  const [slackState, setSlackState] = useState<string>();
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [integrationAdded, setIntegrationAdded] = useState<string>('');

  const baseUrl = `/v0/organizations/${organization.Name}`;
  const POLLING_UPDATED_INTEGRATIONS_TIME = 2000;

  useEffect(() => {
    refreshSlack();
    checkJiraAccount();
    refreshDatadogTokenSet();
    checkPagerDuty();
    checkAzure();
  }, []);

  useEffect(() => {
    githubEnabled();
  }, [repositories]);

  useEffect(() => {
    if (integrationAdded && integrationAdded !== IntegrationsChannels.Datadog) {
      const interval = setInterval(async () => {
        let refreshFunction;
        switch (integrationAdded) {
          case IntegrationsChannels.Slack:
            refreshFunction = refreshSlack();
            break;
          case IntegrationsChannels.Jira:
            refreshFunction = checkJiraAccount();
            break;
          case IntegrationsChannels.Pagerduty:
            refreshFunction = checkPagerDuty();
            break;
          case IntegrationsChannels.Azure:
            refreshFunction = checkAzure();
            break;
          case IntegrationsChannels.Github:
            refreshFunction = store().dispatch(UPDATE_ORG, organization.Name);
            break;
          default:
            refreshFunction = null;
            break;
        }
        await refreshFunction;
      }, POLLING_UPDATED_INTEGRATIONS_TIME);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [integrationAdded]);

  const disconnectIntegration = (integration: string) => {
    switch (integration) {
      case IntegrationsChannels.Slack:
        disconnectSlack();
        break;
      case IntegrationsChannels.Jira:
        disconnectJira();
        break;
      case IntegrationsChannels.Pagerduty:
        disconnectPagerDuty();
        break;
      case IntegrationsChannels.Azure:
        disconnectAzure();
        break;
      default:
        router().push({ name: integration, params: { org: organization.Name } });
        break;
    }
  };

  const disconnectSlack = async () => {
    try {
      await sendRequest('DELETE', `${baseUrl}/slack`, {}, null);
    } catch (e: unknown) {
      if (e instanceof SyntaxError)
        logger.logError('event_disconnect_slack', { message: e.message });
    }
    await refreshSlack();
  };

  const disconnectJira = async () => {
    try {
      await sendRequest('DELETE', `${baseUrl}/jira`, {}, null);
    } catch (e: unknown) {
      if (e instanceof SyntaxError)
        logger.logError('event_disconnect_slack', { message: e.message });
    }
    await checkJiraAccount();
  };

  const disconnectPagerDuty = async () => {
    try {
      const response = await sendRequest('DELETE', `${baseUrl}/pagerduty`, {}, null);
      if (response.Success) {
        checkPagerDuty();
      }
    } catch (e) {
      logger.logError('event_disconnect_pagerduty', e);
    }
  };

  const disconnectAzure = async () => {
    try {
      const response = await sendRequest('DELETE', `${baseUrl}/azure`, {}, null);
      if (response.Success) {
        checkAzure();
      }
    } catch (e) {
      logger.logError('event_disconnect_azure', e);
    }
  };

  const refreshSlack = async () => {
    if (!baseUrl) return;
    let response: SlackIntegration;
    try {
      response = await sendRequest('GET', `${baseUrl}/slack/integration`, {}, null);
    } catch (e: unknown) {
      if (e instanceof SyntaxError)
        logger.logError('event_get_slack_integration', { message: e.message });
    }
    setSlackState(() => response?.SlackState);
    setRedirectUrl(() => response?.RedirectURL);
    setSlackClientId(() => response?.SlackClientID);
    if (integrationAdded === IntegrationsChannels.Slack)
      setIntegrationAdded(() =>
        response?.IsSlackAccessTokenSet ? '' : IntegrationsChannels.Slack,
      );
    setStatus((prevStatus) => ({
      ...prevStatus,
      slack: response?.IsSlackAccessTokenSet ? 'installed' : 'available',
    }));
  };

  const githubEnabled = () => {
    const enabled =
      repositories.filter((x) => x.InstallationID).length > 0 ? 'installed' : 'available';
    setStatus((prevStatus) => ({
      ...prevStatus,
      github: enabled,
    }));
    if (integrationAdded === IntegrationsChannels.Github)
      setIntegrationAdded(enabled === 'installed' ? '' : IntegrationsChannels.Github);
  };

  const checkJiraAccount = async () => {
    try {
      const projects = await sendRequest('GET', `${baseUrl}/jira/projects`, {}, null);
      if (Object.keys(projects).length > 0) {
        updateJiraState(true);
        setIntegrationAdded('');
      }
    } catch (e: any) {
      if (e.status === 404) {
        updateJiraState(false);
      } else {
        logger.logEvent('event_get_jira_projects', { message: e.message });
      }
    }
  };

  const updateJiraState = (jira: boolean) => {
    setStatus((prevStatus) => ({ ...prevStatus, jira: jira ? 'installed' : 'available' }));
  };

  const refreshDatadogTokenSet = async () => {
    try {
      const settings = await sendRequest('GET', `${baseUrl}/datadog`, {}, null);
      setStatus((prevStatus) => ({
        ...prevStatus,
        datadog: settings?.IsDatadogTokenSet ? 'installed' : 'available',
      }));
    } catch (e: any) {
      logger.logError('event_get_datadog_token', { message: e.message });
    }
  };

  const checkPagerDuty = async () => {
    try {
      const tokenSet = await sendRequest('GET', `${baseUrl}/pagerduty/integration`, {}, null);
      setStatus((prevStatus) => ({
        ...prevStatus,
        pagerduty: tokenSet?.isAccessTokenSet ? 'installed' : 'available',
      }));
      if (tokenSet.isAccessTokenSet) setIntegrationAdded('');
    } catch (e: any) {
      logger.logError('event_get_pagerduty_token', e);
    }
  };

  const checkAzure = async () => {
    try {
      const tokenSet = await sendRequest('GET', `${baseUrl}/azure/settings`, {}, null);
      setStatus((prevStatus) => ({
        ...prevStatus,
        azure: tokenSet?.IsAccessTokenSet ? 'installed' : 'available',
      }));
      if (tokenSet.isAccessTokenSet) setIntegrationAdded('');
    } catch (e: any) {
      logger.logError('event_get_azure_token', e);
    }
  };

  const connectIntegration = (integration: string) => {
    const slackLink = `https://slack.com/oauth/v2/authorize?client_id=${slackClientId}&scope=channels:read,channels:join,chat:write&redirect_uri=${redirectUrl}&state=${slackState}`;

    setIntegrationAdded(integration);

    switch (integration) {
      case IntegrationsChannels.Slack:
        window.open(slackLink, '_blank');
        break;
      case IntegrationsChannels.Pagerduty:
        window.open(`${baseUrl}/pagerduty/auth`, '_blank');
        break;
      case IntegrationsChannels.Azure:
        window.open(`${baseUrl}/azure/auth`, '_blank');
        break;
      default:
        router().push({ name: integration, params: { org: organization.Name } });
        break;
    }
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${organization.Name}/settings`,
    },
    {
      id: 'last',
      label: strings.navigation.Integrations,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="integrations-page">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!isOrgOwner && (
        <p className="integrations-page__non-owner">
          You are only allowed to change these settings if you are an owner. Please contact your
          organization's owner to make changes.
        </p>
      )}
      <div className="card-list">
        <div className="card-list__section">
          {(integrationsTitles || []).map((title: string) => {
            return (
              <ReportIntegrationCard
                className="integrations-integration-card"
                title={title}
                name={(INTEGRATIONS_DETAILS as HashMap)[title]?.name}
                description={(INTEGRATIONS_DETAILS as HashMap)[title]?.description}
                status={(status as HashMap)[title]}
                isOrgOwner={isOrgOwner}
                connectIntegration={connectIntegration}
                disconnectIntegration={disconnectIntegration}
                isIntegrationPage
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Integrations;
